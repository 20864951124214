import React, {useEffect} from 'react';
import {classes} from '../BillingDetails/BillingDetails.st.css';
import {useControllerProps} from '../../../../../Widget/ControllerContext';
import {FormLoader} from '../../../../../Form/FormLoader/FormLoader';
import {useBillingData} from '../../WithBillingData';
import {usePaymentsApi} from '../../../../../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {updateMandatoryFields} from '../../../../../../../domain/utils/cashier.utils';
import {BillingForm} from '../../../../../Form/BillingForm/BillingForm';
import {getCountryFromAddressFormValues} from '../../../../../Form/form.utils';
import {EmailField} from '../../../../../../../common/components/EmailField/EmailField';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {FIELDS_GAP} from '../../../../../constants';

export enum BillingDetailsFormDataHook {
  billingForm = 'BillingDetailsForm.billingForm',
  billingLeanFlowEmail = 'BillingDetailsForm.billingLeanFlowEmail',
}

export const BillingDetailsForm = () => {
  const {
    formsStore: {areFormsLoaded},
    paymentStore: {setCashierMandatoryFields},
    stepsManagerStore: {isLeanFlow},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  const {billingFormData, email, setEmail, isEmailValid, updateContactCountry} = useBillingData();

  const {paymentsApi} = usePaymentsApi();

  const country = getCountryFromAddressFormValues(billingFormData.formValues);

  useEffect(
    () => {
      void updateContactCountry(country);
      void updateMandatoryFields(paymentsApi, setCashierMandatoryFields, country);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [country]
  );

  return (
    <>
      {!areFormsLoaded && /* istanbul ignore next */ <FormLoader />}
      {areFormsLoaded && (
        <div
          data-hook={BillingDetailsFormDataHook.billingForm}
          className={classes.forms}
          style={{
            gap: `${FIELDS_GAP}px`,
          }}>
          {isLeanFlow && (
            <EmailField
              dataHook={BillingDetailsFormDataHook.billingLeanFlowEmail}
              className={classes.email}
              label={localeKeys.ecom.email.label()}
              value={email}
              hasError={!isEmailValid}
              onChange={setEmail}
            />
          )}
          <BillingForm {...billingFormData} />
        </div>
      )}
    </>
  );
};
