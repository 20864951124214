import React, {useEffect} from 'react';
import {MemberInfoBox} from '../MemberInfoBox/MemberInfoBox';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepsManager} from '../CheckoutSteps/StepsManager/StepsManager';
import {CustomerDetailsStep} from '../CheckoutSteps/Steps/CustomerDetailsStep/CustomerDetailsStep';
import {StepId} from '../../../types/checkoutApp.types';
import {ErrorDialog} from '../ErrorDialog/ErrorDialog';
import {DeliveryMethodStep} from '../CheckoutSteps/Steps/DeliveryMethodStep/DeliveryMethodStep';
import {Violations} from '../Violations/Violations';
import {PaymentAndPlaceOrderStep} from '../CheckoutSteps/Steps/PaymentAndPlaceOrderStep/PaymentAndPlaceOrderStep';
import {classes} from './FullFlow.st.css';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {classes as commonClasses} from '../../../common/common.st.css';
import {CheckoutSlot} from '../CheckoutSlot';
import {SlotId} from '../../../domain/utils/slotId';
import {SPECS} from '../../../common/constants';
import {trackInitiateCheckout} from '../../../domain/utils/analytics.utils';

export enum FullFlowDataHook {
  root = 'FullFlow.root',
  title = 'FullFlow.title',
  expressCheckout = 'FullFlow.expressCheckout',
  violations = 'FullFlow.violations',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const FullFlow = () => {
  const {
    checkoutStore: {
      updateCheckoutError,
      placeOrderError,
      checkout,
      shouldShowViolations,
      isShippingFlow,
      defaultViolationsToDisplay,
    },
    stepsManagerStore: {shouldDisplayExpressCheckout, stepsList, activeStep},
    memberStore: {isMemberAreaAppInstalled},
    paymentStore: {expressPaymentMethodsAmount},
    navigationStore: {trackEvent},
  } = useControllerProps();
  const {experiments} = useExperiments();

  const isOnPlaceOrderStep = activeStep.stepId === StepId.paymentAndPlaceOrder;
  const shouldShowViolationsTopBanner = shouldShowViolations && !isOnPlaceOrderStep;

  useEffect(
    () => {
      trackEvent(...trackInitiateCheckout({checkout}));
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  const fullFlowContent = () => (
    <>
      <div className={commonClasses.oneColumnViewPadding}>
        {shouldShowViolationsTopBanner && (
          <Violations
            dataHook={FullFlowDataHook.violations}
            isTopBanner={true}
            violations={
              experiments.enabled(SPECS.SupportDeliveryViolationsOnCheckout) ? defaultViolationsToDisplay : undefined
            }
          />
        )}
        <CheckoutSlot className={classes.TopCheckoutSlot} slotId={SlotId.TopCheckoutSlot} />
        {shouldDisplayExpressCheckout && (
          <div
            data-hook={FullFlowDataHook.expressCheckout}
            className={expressPaymentMethodsAmount > 0 ? classes.expressCheckout : ''}>
            <ExpressCheckoutButtons
              layout="horizontal"
              isShippable={isShippingFlow}
              estimatedTotal={`${checkout.payNowTotalAfterGiftCard?.amount}`}
            />
          </div>
        )}
        {isMemberAreaAppInstalled && <MemberInfoBox />}
        <CheckoutSlot className={classes.BeforeStepsCheckoutSlot} slotId={SlotId.BeforeStepsCheckoutSlot} />
      </div>
      <StepsManager>
        {stepsList.map((step) => {
          switch (step.id) {
            case StepId.customerDetails:
              return <CustomerDetailsStep key={StepId.customerDetails} />;
            case StepId.deliveryMethod:
              return <DeliveryMethodStep key={StepId.deliveryMethod} />;
            case StepId.paymentAndPlaceOrder:
              return <PaymentAndPlaceOrderStep key={StepId.paymentAndPlaceOrder} />;
            /* istanbul ignore next */
            default:
              return null;
          }
        })}
      </StepsManager>
      {(checkout.errors.noItemsError || placeOrderError || updateCheckoutError) && <ErrorDialog />}
    </>
  );

  return <div data-hook={FullFlowDataHook.root}>{fullFlowContent()}</div>;
};
