import {TextField} from 'wix-ui-tpa/cssVars';
import React from 'react';

export const EmailField = ({
  dataHook,
  className,
  label,
  value,
  hasError,
  onChange,
}: {
  dataHook: string;
  className: string;
  label: string;
  value?: string;
  hasError: boolean;
  onChange: (value: string) => void;
}) => {
  return (
    <TextField
      data-hook={dataHook}
      className={className}
      label={label}
      value={value}
      required
      error={hasError}
      errorMessage={'Enter an email address like example@mysite.com.'}
      newErrorMessage={true}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
